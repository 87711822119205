span.info-tooltip {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 18px;

    div {
        position: absolute;
        right: -0.5rem;
        top: 0;
        transform: translate(100%, -30%);
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.25s linear;
        z-index: 1000;
        border: 1px dashed black;

        &.left {
            transform: translate(-100%, -30%);
            right: unset;
            left: 0.5rem;
        }

        p {
            padding: 1rem;
            margin: 0;
            font-size: 1.4rem;
            background-color: white;
            color: black;
            min-width: 300px;
        }
    }

    &:hover div {
        visibility: visible;
        opacity: 1;
    }
}

.blue-tooltip span.info-tooltip {
    font-size: 22px;
    line-height: .8rem;
    margin-left: 3px;

    svg {
        max-height: 1.2rem;
    }

    path {
        fill: #3a9bff!important;
    }
    img {
        opacity: .5;
    }
    div {
        right: 0.5rem;
        transform: translate(-4%, -30%);
        border-radius: 2px;
        box-shadow: 0 1px 2px rgba(71, 111, 255, .5);
        background-color: #FFFFFF;
        border: 1px solid #3a9bff;
        white-space: normal;

        p {
            padding: .5rem 1rem;
            font-size: 1.1rem;
            color: #3a9bff;
        }
    }

    &.toRight div {
        left: 3rem;
    }
}
