.cluster-ico {
  opacity: 1;
  visibility: visible;
  transition: all .3s;

  &-label {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    color: #ff1e1e;
    font-weight: 700;
    font-size: 1.3em;
  }

  &-is-hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.overlay-popup {
  z-index: 1000;
  border: 2px solid red;
  color: #333;
  background-color: #FFF;
  padding: 9px 0 9px 9px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .7);
  width: 250px;
  height: 110px;
  position: absolute;
  left: -110px;
  bottom: 48px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: all .3s;

  &-single {
    left: -119px;
    bottom: 41px;
  }

  &-is-visible {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -21px;
    left: 50%;
    border-style: solid;
    border-width: 20px 20px 0;
    border-color: red transparent;
    display: block;
    width: 0;
    z-index: 0;
    margin-left: -11px
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -18px;
    left: 50%;
    border-style: solid;
    border-width: 18px 18px 0;
    border-color: #FFF transparent;
    display: block;
    width: 0;
    z-index: 1;
    margin-left: -9px;
  }

  &-names-holder {
    height: 100%;
    overflow-y: auto;
  }

  &-close-btn {
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 1.35em;
    border-radius: 50%;
    border: 2px solid #cecece;
    background-color: #EEE;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -15px;
    top: -15px;
    padding-left: 1px;
    transition: all .3s;
    cursor: pointer;

    &:hover, &focus {
      border-color: red;
      color: red;
    }
  }

  h6 {
    font-weight: 700;
    margin: 0;
    padding: 0;
    font-size: 1.3em;
    padding-bottom: 4px;
    text-transform: capitalize;
  }

  ol {
    margin: 0;
    padding: 0;
    list-style-position: inside;

    li {
      font-weight: 400;
      font-size: 1.15em;
      margin: 0;
      padding: .25em 0;
    }
  }
}