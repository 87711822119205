.b2b .nav-tabs {
    display: flex;
    margin-top: 1rem;
}
.b2b .nav-tabs > li {
    flex: 1;
    text-align: center;
}
.b2b .tab-content {
    padding: 2rem 0 0;
}
.b2b textarea {
    width: 90%;
    padding: 5px;
    border-radius: 4px;
    text-align: left;
    background: #fff;
}
.b2b thead th:first-of-type {
    width: 30%;
}
.b2b tbody tr {
    background-color: #f9f9f9;
}
.b2b .d-none {
    display: none;
}
