.TimeReportEdit {
  table > tbody > tr > td {
    padding: 4px 8px;
  }
  .Title {
    font-size: 2.2rem;
    line-height: 3.4rem;
  }

  .btn-sm {
    font-size: 1.4rem;
    padding: 2px 6px;
    border-width: 1px;
    line-height: 2;
    margin: 6px 3px;
  }

  .border-input {
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 2px 5px;
    border-radius: 4px;
    width: 100%;
    font-size: 1.4rem !important; }

  .hoverInput {
    padding: 2px 5px;
    font-size: 1.4rem !important;
  }

  .d-flex {
    display: flex;
  }
  .w-100 {
    width: 100%;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .align-items-center {
    align-items: center;
  }

  .TimeReportOvertime {
    position: relative;

    table {
      margin-bottom: 0;
    }

    thead th {
      border: none;
      padding: 2px 8px;
      white-space: nowrap;

      &:first-child {
        padding-left: 8px!important;
      }
    }

    .info {
      color: red;
      font-weight: bold;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 50px solid rgba(255, 0, 0, 0.7);
      z-index: 1;
      padding: 20px;
    }

    textarea {
      height: 2.6rem;

      &:read-only {
        cursor: default;
        border: 2px solid transparent;
        background: transparent;
      }
    }
  }
  .bg-grey {
    background: #eaeaea;
  }
  .bg-lightgrey {
    background: #f5f5f5;
  }
  .noEvents {
    pointer-events: none;
    opacity: 0.1;
  }

  .border-input {
    min-width: 50px;
  }
  .DateInput.DateInput_1 {
    width: 100px;
  }
  .DateInput_input {
    font-size: 14px;
  }
  .SingleDatePickerInput_calendarIcon.SingleDatePickerInput_calendarIcon_1 {
    padding: 2px;
  }

}
