.StatsCard2 a {
    width: 100%;
    text-align: center;
    color: #1DC7EA;
    padding: 0 15px;
    text-transform: uppercase;
    border-bottom: 2px solid transparent;
    cursor: default;
}

.StatsCard2 .Link:hover {
    cursor: pointer;
    border-color: #1DC7EA;
}
.StatsCard2 .numbers {
    color: #555;
}