.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
    margin-right: 8px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

.red input:checked + .slider {
    background-color: #ff7700;
}

.red input:focus + .slider {
    box-shadow: 0 0 1px #ff7700;
}

input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.switch-container {
    display: inline-block;
    margin: 0;
}
.switch-container label {
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    color: #9a9a9a;
}
