$breakpoints: (
  sm: 576px,
  md: 782px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);

$breakpoints2: (
  576px,
  768px,
  992px,
  1200px,
  1440px
);

@mixin mq($media-query) {
  @media (min-width: map-get($breakpoints, $media-query)) {
    @content;
  }
}

@mixin font-responsive($min-font-size, $max-font-size, $breakpoints-qty: 5) {

  $font-size-temp: $min-font-size;

  font-size: $font-size-temp * 1px;
  $font-diff-step: ($max-font-size - $min-font-size) / $breakpoints-qty;


  @for $i from 1 through $breakpoints-qty {
    $c: nth($breakpoints2, $i);
    @media (min-width: $c) {
      $current-font-size: round($min-font-size + ($font-diff-step * $i));
      @if $current-font-size!=$font-size-temp {
        font-size: $current-font-size * 1px;
      }
      $font-size-temp: $current-font-size;
    }
  }
}

.cg-survey {
  max-width: 1920px;
  height: calc(100vh - 124px);
  min-height: calc(100vh - 124px);
  margin: 0 auto;
  background-color: #F9F9F9;
  overflow: hidden;

  @include mq(md) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .survey-header {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 66px;
    
    @include mq(md) {
      width: 25%;
      max-height: 1073px;
      height: auto;
    }
    @include mq(xxl) {
      width: 22%;
    }

    .steps-navigation {
      display: none;
      max-height: 100%;
      overflow-y: auto;

      @include mq(md) {
        display: block;
        float: right;
        padding: 20px 30px;
      }

      @include mq(xxl) {
        padding: 30px;
      }

      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;

      .step-container {
        position: relative;
        padding: 10px 0;

        @include mq(xxl) {
          padding: 20px 0;
        }

        &::before {
          position: absolute;
          content: '';
          height: 100%;
          width: 3px;
          background-color: #BCBDBD;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          transition-duration: .3s;
        }

        &:first-of-type::before {
          height: 50%;
          top: auto;
          bottom: 0;
        }
        
        &:last-of-type::before {
          height: 50%;
        }

        .step-dot {
          position: relative;
          background-color: #BCBDBD;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          cursor: pointer;
          transition-duration: .3s;

          @include mq(xxl) {
            width: 40px;
            height: 40px;
          }
  
          &::before {
            content: '';
            position: absolute;
            width: 21px;
            height: 21px;
            border: 3px solid #BCBDBD;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            transition-duration: .3s;
            
            @include mq(xxl) {
              width: 34px;
              height: 34px;
            }
          }

          &:hover,
          &.current {
            &::before {
              width: 41px;
              height: 41px;
              transition-duration: .3s;
              @include mq(xxl) {
                width: 47px;
                height: 47px;
              }
            }
          }

          span {
            color: #ffffff;
            font-size: 14px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);

            @include mq(xxl) {
              font-size: 16px;
            }
          }
        }

        &.active {
          &::before {
            background-color: #6B59D3;
            transition-duration: .3s;
          }

          .step-dot {
            background-color: #6B59D3;
            transition-duration: .3s;

            &::before {
              border: 3px solid #6B59D3;
            }
          }
        }
      }

      
    }
  }

  .survey-body {
    padding: 2% 5%;
    
    @include mq(md) {
      padding: 2% 8%;
      width: 75%;
      overflow-y: auto;
    }

    @include mq(xxl) {
      width: 80%;
    }
  }

  .step-nr {
    @include font-responsive(13,18)
  }

  .question, .title {
    margin: .5em 0;
    font-weight: 900;
    @include font-responsive(21,36);
  }

  .greet {
    @include font-responsive(18,24);
    font-weight: 600;
    line-height: 1.4;
    color: #5F5F63;
    margin: 0;
  }

  .greet {
    margin-top: 30px;
  }

  .text {
    @include font-responsive(14,20);
    color: #5F5F63;
    line-height: 1.6;
  }

  .multi-choice {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 3px;
    column-gap: 8px;
    max-height: 50vh;
    overflow-y: auto;
    width: 104%;
    padding-right: 4%;

    @include mq(md) {
      row-gap: 15px;
      column-gap: 20px;
    }

    &--card {
      @include font-responsive(13,17);
      position: relative;
      font-weight: 600;
      background-color: #ffffff;
      color: #5F5F63;
      padding: 8px 13px;
      border: 2px solid #EDEDED;
      border-radius: 2px;

      @include mq(md) {
        padding: 10px 15px;
      }

      &.selected {
        border: 2px solid #6B59D3;
        box-shadow: 2.5px 4.33px 35px 0px #6B59D32B;
      }

      input[type="checkbox"] {
        opacity: 0;
        position: absolute;
      }

      label {
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        display: inline-block;
        line-height: 25px;

        @include mq(md) {
          padding-left: 45px;
        }
      }

      label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 25px;
        width: 25px;
        border: 2px solid #EDEDED;
      }

      input:checked + label:before {
        background-color: #6B59D3;
      }

      label:after {
        content: '';
        position: absolute;
        left: 10px;
        top: 3px;
        width: 8px;
        height: 15px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        display: none;
      }

      input:checked + label:after {
        display: block;
      }
    }

  }

  button {
    @include font-responsive(19,21);
    background-color: #6B59D3;
    border: 1px solid #6B59D3;
    border-radius: 10px;
    color: #ffffff;
    padding: 15px 30px;
    cursor: pointer;
    transition-duration: .3s;

    &:hover {
      filter: brightness(0.9);
      transition-duration: .3s;
    }

    &.r {
      float: right;
    }

    
    margin-top: 15px;

    @include mq(md) {
      margin-top: 30px;
    }
  }

  ::-webkit-scrollbar {
    width: 13px;
  }
  ::-webkit-scrollbar-track {
    background-color: #D9D9D9;
    border-radius: 6.5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #2F3146;
    border-radius: 6.5px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }

  .fadeIn {
    animation: fadeIn 0.5s linear;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}