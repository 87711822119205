/* DataGrid */

.react-grid-Container {
    color: #333;
    font-size: 12px;
    margin-left: -15px;
}
.react-grid-Container .ico2 {
    font-size: 12px;
    cursor: pointer;
    /*border: 1px solid #4a8de2;*/
    border-radius: 2px;
    display: block;
    height: 12px;
    line-height: 25px;
    /*margin: 5px 3px;*/
    margin-right: 14px;
    padding: 5px 0;
    /*width: 50%;*/
    /*background: #fff;*/
}
.react-grid-Container .ico2:hover {
    /*background-color: #a2c6e2;*/
    border-color: #024b96;
    color: #000;
}
.react-grid-HeaderCell {
    font-size: 11px;
    font-weight: 400;
}
.react-grid-HeaderCell--frozen {
    z-index: 99!important;
}
.react-grid-Cell {
    /*background-color: transparent!important;*/
    padding-right: 2px!important;
    -webkit-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;
    cursor: default;
}
.rdg-selected {
    /*border: 2px solid #66afe9;*/
    border: none!important;
}
.rdg-last--frozen {
    display: flex;
}
.react-grid-Container .editable {
    background: #DCEFE3;
    padding: 7px;
    min-height: 95%;
    text-align: right;
}
.react-grid-Container .amount {
    text-align: right;
}
/*.rdg-editor-container*/
input.editor-main, select.editor-main {

    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 6px!important;
    /*padding: 0!important;*/
    font-size: 12px!important;
    line-height: 1.42857143;
    color: #555;
    /*color: red!important;*/
    /*background-color: #DCEFE3!important;*/
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 3px!important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: none;
}

/*input.editor-main:focus,*/
/*select.editor-main:focus {*/
    /*color: red!important;*/
    /*border-color: #66afe9;*/
    /*border: 2px solid #66afe9;*/
    /*background: #eee;*/
    /*border-radius: 4px;*/
    /*font-size: 8px;*/
/*}*/

.react-grid-Main{
    /*height: 63vh;*/
}

.react-grid-Container{
    /*height: 65vh;*/
}

.react-grid-Grid{
    min-height: 65vh!important;
    /*min-height:     500px!important;*/
}

.react-grid-Canvas{
    /*height: 100% !important;*/
}
