.Settlements {
    color: #222;
}
.Settlements .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
    padding: 1px 8px;
}
.Settlements .breadcrumb a {
    border-bottom: 1px solid transparent;
}
.Settlements .breadcrumb a:hover {
    border-color: #1DC7EA;
}
.Settlements .card {
    margin-bottom: 15px;
}
.SettlementsDetails input[type='text'],
.OfficeClients input[type='text'] {
    width: 100% !important;
}
.Settlements .error,
.Settlements .form-control.error{
    border-bottom: 1px solid red!important;
    border-radius: 0;
}
.Settlements .hover-button {
    font-size: 11px;
    background: #fff;
    border-radius: 3px;
    border: 2px solid #aeaeae;
    color: #888;
    visibility: hidden;
    padding: 2px 6px;
}
.Settlements  tr:hover .hover-button {
    visibility: visible;
}


/* settlements-details */

.Settlements .settlements-details {
    display: flex;
    flex-wrap: wrap;
}
.Settlements .settlements-details > div {
    width: 49%;
    margin: 5px 0;
    /*border-top: 1px solid #eaeaea;*/
    /*padding: 10px;*/
    padding: 0 10px;
}

.Settlements .settlements-details .card label {
    margin-bottom: 0;
}




.Settlements .table > tbody > tr > td {
    font-size: 12px;
    padding: 2px 6px;
    border-color: #eaeaea;
}
.Settlements .table > thead > tr > th {
    font-size: 11px;
    padding: 2px 6px;
}
.Settlements .btn-sm {
    font-size: 11px;
    padding: 2px 6px;
    border-width: 1px;
}
.Settlements .btn-sm.accept-btn {
    margin-bottom: 3px;
}
.Settlements .accept-cell-icon {
    margin-left: 15px;
    margin-top: 5px;
    font-size: 22px;
}

.Settlements .accept-cell-icon.pe-7s-check {
    color: #008000;
}
.Settlements .Users .accept-cell-icon.pe-7s-check {
    font-size: 16px;
}
.users-modal-table td {
    vertical-align: top!important;
}
.users-modal-dialog {
    width: 1200px!important;
}
.Settlements .accept-cell-icon.pe-7s-help1 {
    color: grey;
}

/*Tooltip*/

.Settlements .tool-tip {
    position: relative;
    display: inline-block;
}

.Settlements .tool-tip .tool-tiptext {
    visibility: hidden;
    background-color: #000;
    color: #fff;
    padding: 2px 10px;
    font-size: 11px;
    border-radius: 6px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 85px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 400;
}

.Settlements .tool-tip:hover .tool-tiptext {
    visibility: visible;
}

.Settlements .form-control {
    height: 30px;
    font-size: 12px;
    padding: 6px 12px;
}
.Settlements .Orders .form-control {
    width: 100%;
}
.Settlements .card label {
    font-size: 10px;
}
.Settlements .card .content {
    padding: 2px 15px 2px 15px;
    /*padding: 0;*/
}
.Settlements .card .header {
    padding: 5px 15px 0;
}
.Settlements .navbar .btn {
    margin: 3px 5px;
}
.Settlements .navbar select.btn option:disabled {
    color: #b4b4b4;
}
.Settlements .card .table.invoices tbody td,
.Settlements .card .table.invoices thead th {
    padding-left: 5px;
    padding-right: 15px;
    border: none;
}
.Settlements .card .table.invoices th {
    width: 10%;
}
.Settlements .card .table.invoices th:nth-child(1),
.Settlements .card .table.invoices th:nth-child(6){
    width: 5%;
}
.Settlements .card .table.invoices th:nth-child(2),
.Settlements .card .table.invoices th:nth-child(3) {
    width: 30%;
}
.Settlements .card .table.invoices thead th:nth-child(5) {
    text-align: right;
}

.Settlements .card .table.invoices tbody > tr.border {
    border-top: 1px solid #eaeaea;
}
.Settlements .card .table.invoices tbody > tr.border td {
    vertical-align: top;
}
.Settlements .right {
    text-align: right;
}
.Settlements .del {
    width: 16px;
    height: 16px;
    border: 1px solid #555;
    text-align: center;
    border-radius: 50px;
    font-size: 10px;
    line-height: 13px;
    cursor: pointer;
    margin-top: 8px;
}
.Settlements .del:hover {
    background: #999;
    color: #fff;
}

.Settlements input::placeholder {
    color: #b4b4b4;
}

.Settlements .newRender .card {
    padding: 20px 5px;
}
.Settlements .newRender label {
    margin: 10px 0 0 5px;
}
.Settlements .newRender .form-control {
    border: none;
    border-bottom: 1px solid #e3e3e3;
}

.Settlements .DateInput_input {
    padding: 11px 7px 9px;
}

.modalTable {
    margin: 7px 0;
    width: 100%;
}

.modalTable td, .modalTable th {
    width: 25%;
    font-size: 11px;
    padding: 0 10px;
}
.fakeInfo {
    width: 50vw;
    height: 40px;
    background: #000;
    color: #fff;
    position: fixed;
    top: 0;
    line-height: 2.2;
    left: 25vw;
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
}
.Settlements .noEvents {
    pointer-events: none;
    opacity: 0.5;
    background: #fff;
}

.Settlements .not-empty,
.Settlements .not-empty:focus {
    border: 2px solid #3472f7;
}

.Settlements .container-fluid > .row:first-of-type .card .footer {
    display: none;
}

.Settlements .Users table tr {
    height: 29px;
}
.Settlements .Users table th {
    vertical-align: top;
}
.Settlements .Users table a {
    color: #47a7ff;
    text-decoration: underline;
}
.Settlements .Users table a:hover {
    text-decoration: none;
}
