.btnIco {
  border-left: 1px solid #E3E3E3;
  cursor: pointer;
  background-color: #F5F5F5;
}
.btnOwnGroup { margin-right: 5px; margin-top: 5px; }
.btnOwnGroup .btn { border-width: 1px; }
.btnOwnGroup .btn.disabled { cursor: default; }
ul.ownLegend {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0
}
ul.ownLegend li {
  display: inline-block;
  margin-right: 15px;
}
ul.ownLegend li span {
  display: inline-block;
  padding: 8px 15px;
  vertical-align: middle;
  border: 1px solid #EEE;
}
ul.ownLegend li span.bg-info { border: 1px solid #d9edf7 }
ul.ownLegend li span.bg-success { border: 1px solid #dff0d8 }

.form-group .DayPickerInput { display: block; }

.adspaceCategories { display: flex; flex-wrap: wrap; padding: 0 15px; }
.adspaceCategories [class*="col-"] { border: 1px solid #EEE; float: none; display: block; width: 20%; margin-top: -1px; margin-left: -1px; }
.adspaceCategories .ownCheckbox { text-align: left; width: 100%; margin-top: 5px; margin-bottom: 0; }
.adspaceCategories .ownCheckbox.secondaryCat { margin-left: 20px; }
.adspaceCategories .ownCheckbox label { margin: 0; font-size: 11px }
.adspaceCategories .ownCheckbox input[type=checkbox] { margin: -1px 5px 0 0; vertical-align: middle; }

@media (max-width: 1380px) {
  .adspaceCategories [class*="col-"] { width: 25%; }
}

@media (max-width: 992px) {
  .adspaceCategories [class*="col-"] { width: 50%; padding: 6px; }
}

@media (max-width: 768px) {
  .adspaceCategories [class*="col-"] { width: 100%; padding: 6px; }
}
