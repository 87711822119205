.react-month-picker {
    display: flex;
    align-items: center;
}
.react-month-picker .form-control {
    height: 30px;
    /*width: 120px!important;*/
}
.react-month-picker .edit {
    border: 1px solid #ddd;
    color: #555;
    padding: 7px;
    border-radius: 3px;
    margin: 5px;
    font-size: 12px;
    display: inline-block;
}
.react-month-picker label {
    text-transform: none!important;
    margin-bottom: 0!important;
}
.react-month-picker .month-picker label {
    color: #666!important;
}
.react-month-picker .rmp-btn.active {
    background-color: #00a699!important;
}
.react-month-picker .rmp-btn:hover {
    background-color: #e4e7e7!important;
}
