.mb-0 {
  margin-bottom: 0 !important;
}
.mt-1 {
  margin-top: 10px !important;
}

.d-none {
  display: none !important;
}

.w-100 {
  width: 100% !important;
}

#sidebar {
  z-index: 9999;
}

#sidebar .sidebar-wrapper {
  width: 90px;
  transition: width ease 0.3s;
  overflow: hidden;
}

#sidebar .nav-link {
  height: 50px;
  width: 60px;
}

#sidebar .nav-link .full-menu {
  height: 50px;
  width: 170px;
}
#sidebar .full-menu {
  width: 260px;
  overflow: auto;
  transition: width ease 0.3s;
}

#sidebar .full-menu::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

#sidebar .full-menu::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

#sidebar .full-menu::-webkit-scrollbar-thumb {
  background-color: #ff0000;
}

#sidebar .sidebar-wrapper .full-menu {
  width: 230px;
  overflow: hidden;
  white-space: nowrap;
}

#main-panel {
  width: calc(100% - 90px);
}

.btn-group .btn-save,
.navbar .btn-save {
  border-color: #333;
  color: #111;
}

.amount {
  text-align: right;
}

.amount:read-only,
.timereport input:not(.btn):read-only {
  /*opacity: 0.5;*/
  cursor: default;
  border: 2px solid transparent;
  background: transparent;
}

.hoverInput {
  text-align: right;
  border: 1px solid transparent;
  background: transparent;
  padding: 8px 10px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
}

.border-input {
  border: 1px solid black;
  padding: 8px 10px;
  border-radius: 4px;
  width: 100%;
}

.border-input::placeholder {
  color: red;
}
.input-error::placeholder {
  color: red !important;
}
.green {
  color: #87cb16;
}

.blue {
  color: #1dc7ea;
}

tr:hover .hoverInput:not(:read-only),
.hoverInput:focus:not(:read-only) {
  border: 1px solid #b4b4b4;
  background: #fff;
}

tr:not(:last-of-type) .hoverInput::placeholder {
  color: transparent;
}
tr .hoverInput.textarea::placeholder {
  color: #c0c0c0;
}
tr:not(:last-of-type):hover .hoverInput::placeholder {
  color: #c0c0c0;
}

.sup {
  vertical-align: super;
  font-size: 75%;
  color: #777;
}

.sum {
  border: none;
  background: transparent;
  font-weight: bold;
  cursor: default;
}

.sum:hover {
  border: none;
  background: transparent;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: absolute;
  left: 45vw;
  top: 45vh;
  z-index: 1;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.editable-button {
  font-size: 12px;
  background: #fff;
  border-radius: 3px;
  border: 2px solid #aeaeae;
  color: #888;
  display: none;
  padding: 3px 12px;
}
tr:hover .editable-button.edit {
  display: inline-block;
}
tr.editable .editable-button.edit {
  display: inline-block;
}
.editable-button:hover {
  border-color: #777;
  color: #555;
}
.editable-button.save {
  border-color: #7ab714;
  color: #7ab714;
}
.editable-button.save:hover {
  border-color: #57820d;
  color: #57820d;
}
tr:hover .editable-button.save {
  display: none;
}

table.table-condensed thead tr th,
table.table-condensed thead tr td,
table.table-condensed tbody tr td {
  padding: 5px !important;
  font-size: 13px;
}

#own-preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
}
#own-loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e43d4f;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
#own-loader:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e43d4f;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#own-loader:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e43d4f;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
#own-preloader .preloader-text {
  position: absolute;
  bottom: 5px;
  right: 1%;
  color: #e43d4f;
  font-weight: 700;
  animation: text-move 1.5s linear infinite;

}
#own-preloader .preloader-text.preloader-text-right {
  top: 5px;
  bottom: unset;
}
#own-preloader .preloader-text.preloader-text-extremely-visible {
  background-color: #ff0000;
  color: #FFF;
  padding: 10px;
}
@keyframes text-move {
  0% {
    right: 1%;
  }
  25% {
    right: 2.5%;
  }
  50% {
    right: 4%;
  }
  75% {
    right: 2.5%;
  }
  100% {
    right: 1%;
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (min-width: 1200px) {
  .order .form-inline .form-control {
    width: 80%;
  }
}
.order .gen-id input:not(:last-of-type) {
  max-width: 60px;
}
.order .gen-id select {
  max-width: 80px;
}
.nowrap {
  white-space: nowrap;
}
.closed {
  opacity: 0.5;
}
a.link {
  color: #000;
}
a.header-link {
  margin-left: 3%;
}

/*  timereport  */

.table.timereport > tbody > tr > td {
  padding: 3px;
}
a.time {
  color: #a9a9a9;
}
a.time:hover {
  font-weight: bold;
  color: inherit;
}
.weekend {
  opacity: 0.4;
}
.red {
  color: red !important;
}
.green {
  color: green;
}
.dark-blue {
  color: #0068ff;
}
.grey {
  color: #999 !important;
}
.bold {
  font-weight: bold;
}

.timereport-panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -1px;
  align-items: center;
}
.timereport-panel .btn {
  margin: 0 !important;
}
.timereport-panel > div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.timereport-panel > div:last-of-type > div {
  /*padding: 1rem 5rem .1rem 0;*/
  width: 100%;
}
.timereport-panel label {
  margin: 2px 5px !important;
}
.timereport-clients {
  z-index: 0;
  text-align: right;
  max-height: 70vh;
}
.timereport-projects .rt-tr.-odd .rt-td {
  background-color: #f2fff3 !important;
}
.print-dates {
  display: none;
}
@media print {
  .timereport-clients {
    max-height: unset;
  }
  .timereport-clients .cg-row.profit {
    height: 6mm;
  }
  .print-dates {
    display: block;
  }
}
.timereport-clients .cg-row {
  display: block;
  padding: 2px 7px 0 0;
}
.timereport-clients .cg-row.markup {
  background-color: #ecf5f9;
}
.timereport-clients .cg-row.hcva {
  background-color: #def2fb;
}
.timereport-clients .cg-row.profit {
  background-color: #d2e3fd;
}
.timereport-clients .cg-row .green {
  color: #05a605;
}
.timereport-clients .type span {
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  padding: 1px 0;
  display: block;
}
.timereport-clients .rt-tbody .rt-td,
.timereport-clients .rt-tfoot .rt-td {
  padding: 0;
}
.timereport-clients .rt-td.rthfc-td-fixed-left:first-of-type {
  padding: 4px !important;
}
.timereport-clients .rt-tbody .rt-tr-group,
.timereport-clients .rt-tfoot .rt-tr {
  /*border-bottom: 1px solid #e9e9e9!important;*/
  border-bottom: 1px solid #a7a7a7 !important;
}
.timereport-clients .rt-tfoot .rt-tr {
  border-top: 1px solid #b9b9b9 !important;
}
.timereport-clients .finance-data {
  margin: 0;
  cursor: pointer;
}
/*
.timereport-clients .comment-tooltip:before {
  content: 'komentarz';
}
.timereport-clients .comment-tooltip {
  width: 0;
  height: 13px;
  cursor: pointer;
  font-size: 10px;
  text-transform: uppercase;
  color: transparent;
  font-weight: bold;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  margin-left: 2px;
  margin-right: 5px;
  margin-top: 2px;
}
.timereport-clients .comment-block:hover .comment-tooltip {
  display: inline-block;
  width: 64px;
  color: #000;
  transition: all .2s;
}
.timereport-clients .comment-block .comment-tooltip:hover {
  background-color: #bad2f9;
}
.timereport-clients .comment-block:hover .small {
  display: none;
}
 */
.timereport-clients .comment-block {
  height: 100%;
}
.timereport-clients .comment-block i {
  color: #555;
  margin-right: 5px;
  font-size: 16px;
  font-weight: bold;
  display: none;
  transform: translateY(1px);
  margin-left: 5px;
}
.timereport-clients .comment-block:hover i,
.timereport-clients .comment-block i.comment-exists {
  display: inline-block;
}
.timereport-clients .task-ico-block i {
  color: #888;
  margin-right: 5px;
  font-size: 14px;
  font-weight: bold;
  display: none;
  transform: translateY(1px);
  margin-left: 5px;
}
.timereport-clients .task-ico-block:hover i {
  display: inline-block;
}
.timereport-clients i:hover {
  color: #0076ff;
  cursor: pointer;
}
.timereport-clients .pe-7s-attention {
  display: inline-block !important;
  margin-left: -5px !important;
  cursor: default !important;
}
.filters-params .footer {
  display: none;
}
.filters-params * {
  margin-right: 7px;
  margin-bottom: 5px;
  border-radius: 4px;
}
.filters-params .filter-row {
  display: flex;
  align-items: center;
}
.filters-params .filter-row > * {
  margin-right: 7px;
}
.filters-params .filter-row > *:not(:nth-child(4)) {
  width: 220px;
  background-color: #fff;
  border: #ccc 1px solid;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #333;
  font-size: 1em;
  padding: 0 5px;
}
.filters-params .filter-row .placeholder,
.filters-params .filter-row input::placeholder {
  color: #949494;
}
.filters-params .btn-sm {
  padding: 1px 7px;
  border-width: 1px;
}

.del {
  display: inline-block;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 16px;
  font-size: 12px;
  cursor: pointer;
  margin-top: 4px;
  /*background: #f1f1f1;*/
  color: #999;
  line-height: 15px;
  font-weight: bold;
  border: 1px solid #dfdfdf;
}
.del:hover {
  background: #d0d0d0;
  border: 1px solid #d0d0d0;
  color: #fff;
  transition: background-color 0.5s;
}
.multiselect .clear {
  text-align: right;
  color: rgb(153, 153, 153);
  padding-right: 6px;
  cursor: pointer;
  display: block;
  text-transform: uppercase;
  font-size: 10px;
}
.multiselect .clear.disable {
  display: none;
}
.multiselect .rw-list-optgroup:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #333;
}

h4.title {
  margin: 0 0 20px 0;
  color: #333;
  font-weight: 300;
  line-height: 18px;
}

.tooltip-holder {
  position: relative;
}
.tooltip-holder .tooltip {
  opacity: 0.75;
  display: none;
}
.tooltip-holder:hover .tooltip,
.tooltip-holder.active .tooltip {
  display: block;
}

i.ico-cursor {
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
i.ico-cursor:hover {
  transform: scale(1.5);
  font-weight: 700;
}

.width-100 {
  width: 100% !important;
}

.form-holder {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  background-color: #fff;
  padding: 15px;
  margin-bottom: 15px;
}
.file-input-holder input[type='file'] {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.file-input-holder label {
  display: flex;
  background-color: #ecf5f9;
  border: 2px dashed #d9edf7;
  cursor: pointer;
  height: 100px;
  color: #5bc0de;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-style: italic;
  font-size: 18px;
  font-weight: 300;
}
.file-input-holder label:hover,
.file-input-holder label:focus {
  border-color: #5bc0de;
}
.file-input-holder.has-error label {
  border-color: #f2dede;
  background-color: #fbf2f2;
}
.file-input-holder.has-error label:hover,
.file-input-holder.has-error label:focus {
  border-color: #d9534f;
}

.creatives-table {
  overflow-x: auto;
}
.creatives-table .table thead tr th {
  font-weight: 700;
  font-size: 13px;
  color: #333;
  padding: 10px !important;
}
.creatives-table input[type='text'] {
  min-width: 250px;
}
.creatives-table input[type='text'][name*='sizeTag'] {
  min-width: 100px;
  width: 100px;
}
.creatives-table input[type='checkbox'] {
  cursor: pointer;
}

.border-b-n {
  border-bottom: 0 none !important;
}
.border-t-n {
  border-top: 0 none !important;
}

.overflow-control {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

.auto-validation p.title {
  font-weight: 400;
  color: #9a9a9a;
  text-transform: uppercase;
  font-size: 14px;
}
.auto-validation .hint {
  color: #bfbfbf;
  font-size: 13px;
  font-weight: 400;
  text-transform: none;
}
.auto-validation label {
  font-weight: 300;
  margin-bottom: 1px;
}
.auto-validation table thead tr th,
.auto-settlements table tbody tr th {
  padding: 8px;
  color: #9a9a9a;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
}
.auto-validation table tbody tr td {
  cursor: pointer;
}
.auto-validation table tbody tr td:hover {
  background-color: #e3e3e3;
}
.auto-validation table tr th.prev {
  width: 50px;
}
.auto-validation .input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.auto-validation table tbody > tr.even {
  background-color: #f9f9f9;
}

.pe-2x {
  font-size: 200%;
}

.pub-form-checkbox {
  margin: 0 10px;
}

.autovalidation-loglist .SingleDatePickerInput__withBorder {
  border-radius: 4px;
  border-color: #ccc;
}
.autovalidation-loglist .DateInput_input__small {
  padding: 8px 5px;
}
.autovalidation-loglist table .bg-info {
  background-color: #f7fcfe;
}
.autovalidation-loglist table .bg-success {
  background-color: #f5fff2;
}
.autovalidation-loglist table .bg-danger {
  background-color: #fff4f4;
}
.autovalidation-loglist table pre {
  width: 100%;
  display: block;
}
.autovalidation-loglist table.table-loglist tbody tr:first-child td {
  border-top-width: 3px;
  border-top-color: #afafaf;
}
.autovalidation-loglist table.summary tbody tr:first-child td {
  border-top: 0 none;
}

.virtual-rooms {
  margin-bottom: 15px;
}
.virtual-rooms h4 {
  margin-top: 0;
}
.virtual-rooms h4 a {
  font-size: 14px;
  line-height: 16px;
  display: block;
}
.virtual-rooms .card {
  margin-bottom: 15px;
}
.virtual-rooms .card-stats .row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.virtual-rooms .card-stats .numbers {
  font-size: 1.6em;
}
.virtual-rooms .numbers p {
  font-size: 14px;
}
.virtual-rooms .footer {
  display: none;
}
.tasks-modal {
  padding: 0 15px;
}
.tasks-modal .row {
  border-bottom: 2px solid #fff;
  padding: 8px 0;
}

.card-stats .numbers .google-login-button {
  width: 100%;
}

.nsm-categories-box {
  height: 200px;
  overflow-y: scroll;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background-color: #ffffff;
}

.adspace-description-box {
  max-width: 300px;
}

.bg-grey {
  background-color: #f7f7f8;
}

.desksBookingControls .DayPickerInput {
  display: inline-block;
  margin: 0 7px;
  vertical-align: middle;
}

.desksBookingControls .btn-secondary {
  border: 1px solid #ccc;
}

.desksBookingControls #desksDateSelect {
  display: block;
  align-self: end;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
}

.switch-radio-box {
  display: flex;
  margin: 10px 0;
}

.switch-radio-box input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
}

.switch-radio-box label {
  font-size: 1em;
  text-align: center;
  padding: 0.5em 2em;
  border: 2px solid grey;
  color: grey;
  cursor: context-menu;
  margin: 0;
}

.switch-radio-box label:not(.checked):hover {
  cursor: pointer;
  filter: brightness(0.9);
}

.switch-radio-box label.checked {
  background-color: grey;
  color: white;
}

.switch-radio-box label.checked {
  background-color: grey;
  color: white;
}

.switch-radio-box label:first-of-type {
  border-radius: 5px 0 0 5px;
}

.switch-radio-box label:last-of-type {
  border-radius: 0 5px 5px 0;
  margin-left: 0;
}
