.comments-modal .comment-row {
    padding: 20px 20px 10px;
    background: #f6f6f6;
    margin-top: 10px;
}
.comments-modal .row {
    margin-bottom: 10px;
}
.comments-modal .comment-row .title {
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
}
.comments-modal .comment-row .text {
    padding: 16px;
    white-space: pre-line;
}
.comments-modal .edit-row-container {
    position: fixed;
    z-index: 2;
    top: 63px;
    margin-right: 15px;
    background-color: #fff;
}
.comments-modal .edit-row {
    padding: 20px;
    background: #b8d4f9;
    margin-top: 12px;
    text-align: left;
    box-shadow: 0 1px 1px rgba(0,0,0,.11),
    0 2px 2px rgba(0,0,0,0.11),
    0 4px 4px rgba(0,0,0,0.11),
    0 5px 5px rgba(0,0,0,0.11);
}
.comments-modal h4 {
    margin: 0;
}
.comments-modal hr {
    border-color: #a0a0a0;
    margin-top: 10px;
    margin-bottom: 15px;
}
.comments-modal .c-label {
    text-transform: uppercase;
    font-size: 11px;
    padding-left: 5px;
}
.comments-modal .btn,
.comments-modal .btn:hover,
.comments-modal .btn:disabled,
.comments-modal .btn:disabled:hover {
    background-color: #fff;
}
.comments-modal .sm-btn {
    padding: 2px 8px;
    border-width: 1px;
    margin-bottom: 5px;
}
.comments-modal  .filter-options {
    background: #eaf0ff;
    padding: 17px 20px 22px;
}
.comments-modal .reply {
    background-color: #fff;
    border: 1px solid #e6e6e6;
    transform: translateY(10px);
}