.ag-header-cell-filtered {
    background-color: #ddeaff;
}
.Settlements .amount-cell {
    width: 100%;
    display: inline-block;
    height: 95%;
    text-align: right;
    border: none;
}
.Settlements .editable-cell, .Settlements .information-cell {
    width: 100%;
    display: inline-block;
    /* background: #DCEFE3; */
    text-align: right;
    border: 1px solid #fff!important;
}
.Settlements .editable-cell.text {
    text-align: left;
}
.Settlements .disable-cell {
    pointer-events: none;
}
.Settlements .bold {
    font-weight: bold;
}
.ag-floating-bottom {
    overflow-y: hidden;
}
.ag-filter-apply-panel button,
.ag-filter-body-wrapper .ag-filter-select,
.ag-filter-body input {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 3px;
    color: #333;
}

.ag-row .ag-cell:not(:first-of-type) {
    -webkit-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    user-select: all;
    cursor: default;
}
.ag-floating-bottom {
    font-weight: bold;
}