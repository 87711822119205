.setHeight .modal-dialog,
.setHeight .modal-content {
    height: 95%;
}
.setHeight .modal-body {
    height: calc(100% - 130px);
    overflow-y: scroll;
}
.setHeight .modal-title > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.modal-wide {
    width: 80%!important;
}